import React from "react"
import { css } from "@emotion/core"
import styled from "@emotion/styled"
import SEO from "../components/SEO"
import Layout from "../components/Layout"
import Header from "../components/Header"
import NextPage from "../components/NextPage"
import MikeyAriSpinImage from "../components/MikeyAriSpinImage"
import OurStoryImage from "../components/OurStoryImage"
import Card from "../components/Card"
import { smallContainer } from "../components/container"
import { colors } from "../theme"

// Our Story Section
const storyWrapper = css`
  margin-top: 50px;
  margin-bottom: 50px;

  @media (min-width: 800px) {
    display: flex;
    flex-direction: row;
  }
`

const StyledCard = styled(Card)`
  max-width: 460px;

  @media (min-width: 860px) {
    max-width: 600px;
  }
`

const heroHeadline = css`
  font-size: 36px;
  position: relative;
  top: -25px;
  margin-bottom: 0;
`

const storyText = css`
  font-size: 18px;
  line-height: 1.3;
  color: ${colors.roseDark};
  margin: 20px 0;
`

const storyVisuals = css`
  position: relative;

  @media (min-width: 800px) {
    flex: 1 1 auto;
    margin-left: -60px;
  }

  @media (min-width: 860px) {
    margin-left: 0;
  }
`

const spinImage = css`
  width: 80%;
  max-width: 400px;
  position: relative;
  top: -40px;
  margin-left: auto;
  margin-right: 0;

  @media (min-width: 800px) {
    width: 424px;
    max-width: none;
    position: absolute;
    top: auto;
    bottom: 200px;
    left: 20px;
  }

  @media (min-width: 860px) {
    bottom: 220px;
  }
`

const botanicalImage = css`
  mix-blend-mode: multiply;
  position: absolute;
  width: 240px;
  bottom: -50px;
  left: -12%;
  transform: scaleX(-1);

  @media (min-width: 500px) {
    width: 280px;
    left: 30px;
    bottom: -70px;
  }

  @media (min-width: 800px) {
    width: 323px;
    top: auto;
    bottom: -120px;
    left: -120px;
    right: auto;
    transform: scaleX(1);
  }

  @media (min-width: 860px) {
    bottom: -90px;
    left: -150px;
  }
`

const OurStory = () => (
  <Layout>
    <SEO title="Our story" />
    <Header />
    <div css={[smallContainer, storyWrapper]}>
      <StyledCard>
        <h1 css={heroHeadline}>Our Story</h1>
        <p css={storyText}>
          Arijana left the former Yugoslavia when news traveled across the
          Atlantic that a boy named Michael was born in Fresno, California in
          1988. As you can imagine, it was a difficult journey to embark upon
          for a then determined 3 year old Arijana. But the tales of his
          glorious locks and beautiful mind covered the land in celebration and
          Arijana firmly &mdash; but politely &mdash; said to her mother,
          &ldquo;We are moving to America.&rdquo;
        </p>
        <p css={storyText}>
          As Michael spent his early developmental years growing an interest in
          technology and the stock market, Arijana dilligently sharpened her wit
          in three languages which she planned to deploy upon meeting Mikey. He
          would become an exceptionally good athlete in semi-pro paintball. She
          would become an exceptionally good athlete in folk dancing. He, a
          scrawny guy who loved mac ‘n cheese. She, a chunky gal who loved
          poetry ‘n choir.
        </p>
        <p css={storyText}>
          Love takes us to for away places. Many nights Arijana called out for
          Michael. She asked the universe for a boy with long brown hair and a
          bright infectious smile. Someone who was not bound by any rules, other
          than to be the funky brilliance he was destined to be. A challenger of
          convention, a man with conviction. And the universe replied,
          &ldquo;He&rsquo;s in California.&rdquo;
        </p>
        <p css={storyText}>
          With her bags in tow, Arijana left Idaho and landed in San Francisco
          and the universe spoke again, &ldquo;I&rsquo;m sorry, I wasn&rsquo;t
          very clear. He&rsquo;s in Santa Ana, California.&rdquo;
        </p>
        <p css={storyText}>
          By the time she found him, lifes&rsquo; adventures had distracted her,
          and she would spend the following year confusing him by disappearing
          in her little cave, on Buffalo street. Love makes us reach deeper
          inside for the other. &ldquo;I like you. And I want you to like me,
          too,&rdquo; Michael said, picking apart Basil leafs in preparation to
          deploy his charm, cooking pasta for Arijana, &ldquo;the best way to
          think of Basil, is like a flower.&rdquo;
        </p>
      </StyledCard>
      <div css={storyVisuals}>
        <div css={spinImage}>
          <MikeyAriSpinImage />
        </div>
        <div css={botanicalImage}>
          <OurStoryImage />
        </div>
      </div>
    </div>
    <NextPage to="/our-party">Our Party</NextPage>
  </Layout>
)

export default OurStory
