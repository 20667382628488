import React from "react"
import { css } from "@emotion/core"
import Button from "./Button"
import { smallContainer } from "./container"
import { colors } from "../theme"

const nextPageWrapper = css`
  display: flex;
  align-items: center;
  font-size: 18px;
  line-height: 24px;
  height: 60px;
  margin-bottom: 0;
  align-self: flex-end;
  padding: 13px 10px 7px 10px;
  border: 1px solid ${colors.shadowLight};
`

const nextLabel = css`
  color: ${colors.roseDark};
  margin-right: 15px;
`

const nextButton = css`
  font-size: 24px;
  font-weight: 500;
  color: ${colors.black};
  &:after,
  &:hover:after {
    background: ${colors.accent};
  }
`

const NextPage = ({ to, children }) => {
  return (
    <div css={smallContainer}>
      <div css={nextPageWrapper}>
        <span css={nextLabel}>Next:</span>
        <Button to={to} css={nextButton}>
          {children}
        </Button>
      </div>
    </div>
  )
}

export default NextPage
